import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Backdrop, LinearProgress, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  backdrop: {
    //zIndex: theme.zIndex.drawer - 1,
    color: '#fff',
  },
  progress: {
    position: 'absolute',
    top: 50,
    width: '100%',
    height: 5,
  },
  info_holder: {
    width: 100,
    height: 100,
  },
}));

export default function Loading(props) {
  const classes = useStyles();
  return (
    <Box>
      <Backdrop className={classes.backdrop} open={true}>
        <LinearProgress className={classes.progress} color="secondary" />
        <Typography variant="h5">{`Loading ${props.text} ...`}</Typography>
      </Backdrop>
    </Box>
  );
}
