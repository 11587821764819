import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import MuiVirtualizedTable from 'components/VirtualizedTable';
import { formatCurrency } from 'utils/utils';
import GridDialog from 'components/GridDialog';

const styles = (theme) => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  table: {
    // temporary right-to-left patch, waiting for
    // https://github.com/bvaughn/react-virtualized/issues/454
    '& .ReactVirtualized__Table__headerRow': {
      flip: false,
      paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
    },
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

const VirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

function createData(
  id,
  name,
  code,
  channel,
  totalOutstanding,
  amountPaid,
  smsStatus,
  paymentStatus
) {
  return {
    id,
    name,
    code,
    channel,
    totalOutstanding,
    amountPaid,
    smsStatus,
    paymentStatus,
  };
}

function calculateTotalOutstanding(data) {
  let total = 0;
  for (const item of data) {
    total = total + item.outstanding;
  }
  return total;
}

export default function Retailers(props) {
  const [rows, setRows] = useState([]);
  const [dialogData, setDialogData] = useState();

  const getDialogData = (data) => {
    const res = data.ars.flatMap((ar) => {
      const r = {
        invoiceCode: ar.code,
        date: ar.date,
        amountCollected: 0,
        modeOfPayment: 'NA',
        paymentStatus: 'NA',
        totalOutstanding: ar.outstanding,
      };
      const pmtsCount = ar.payments.length;
      r['rowSpan'] = pmtsCount || 1;
      return pmtsCount > 0
        ? ar.payments.map((pmt, index) => {
            if (index > 0) {
              delete r['totalOutstanding'];
              delete r['rowSpan'];
              delete r['invoiceCode'];
            }
            return {
              ...r,
              amountCollected: pmt.amountCollected,
              modeOfPayment: pmt.modeDetails?.rzpMethod?.toLocaleUpperCase() || pmt.modeOfPayment,
              paymentStatus: pmt.modeOfPayment === 'UPI' ? pmt.modeDetails.upiTrxStatus : 'success',
            };
          })
        : [r];
    });

    return res;
  };
  const showDialog = ({ event, index }) => {
    event.stopPropagation();
    // console.table(getDialogData(props.retailers[index]));
    setDialogData({
      title: props.retailers[index].name,
      data: getDialogData(props.retailers[index]),
    });
  };

  const getColumns = () => {
    return [
      {
        width: 200,
        label: 'Invoice Code',
        dataKey: 'invoiceCode',
        merge: true,
      },
      {
        width: 250,
        label: 'Initial Outstanding',
        dataKey: 'totalOutstanding',
        numeric: true,
        merge: true,
        formatter: formatCurrency,
      },
      {
        width: 200,
        label: 'Invoice Date',
        dataKey: 'date',
      },
      {
        width: 200,
        label: 'Amount Paid',
        dataKey: 'amountCollected',
        numeric: true,
        formatter: formatCurrency,
      },
      {
        width: 300,
        label: 'Mode Of Payment',
        dataKey: 'modeOfPayment',
      },
      {
        width: 300,
        label: 'Payment Status',
        dataKey: 'paymentStatus',
      },
    ];
  };
  useEffect(() => {
    if (props.retailers) {
      const rows = props.retailers.map((retailer) => {
        return createData(
          retailer.id,
          retailer.name,
          retailer.code,
          retailer.channel,
          calculateTotalOutstanding(retailer.ars),
          retailer.amountCollected,
          retailer.smsSent ? 'Sent' : 'Not Sent',
          retailer.paymentStatus
        );
      });
      setRows(rows);
    }
  }, [props.retailers]);

  return (
    <>
      <Paper style={{ width: '100%', height: window.innerHeight - 235 }}>
        <VirtualizedTable
          rowCount={rows.length}
          rowGetter={({ index }) => rows[index]}
          onRowClick={showDialog}
          columns={[
            {
              width: 300,
              label: 'Retailer Name',
              dataKey: 'name',
            },
            {
              width: 150,
              label: 'Code',
              dataKey: 'code',
            },
            {
              width: 180,
              label: 'Channel',
              dataKey: 'channel',
            },
            {
              width: 150,
              label: 'Total Outstanding',
              dataKey: 'totalOutstanding',
              numeric: true,
              formatter: formatCurrency,
            },
            {
              width: 150,
              label: 'Amount Paid',
              dataKey: 'amountPaid',
              numeric: true,
              formatter: formatCurrency,
            },
            {
              width: 150,
              label: 'SMS Status',
              dataKey: 'smsStatus',
            },
            {
              width: 150,
              label: 'Payment Status',
              dataKey: 'paymentStatus',
            },
          ]}
        />
      </Paper>
      {dialogData && <GridDialog columns={getColumns()} dialogData={dialogData} />}
    </>
  );
}
