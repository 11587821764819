import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Container } from '@material-ui/core';
import Select from 'react-select';
import MenuList from 'components/MenuList';
import { transactionFilters } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    width: '100%',
    paddingBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  filterHolder: {
    width: '33.33%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const selectedFilters = {
  retailerIds: [],
  salesmanIds: [],
  channels: [],
  transaction: [],
};

export default function Filters(props) {
  const {
    dispatch,
    actionType: { FILTERS },
  } = props;
  const classes = useStyles();
  const [retailerOptions, setRetailerOptions] = useState();
  const [salesmanOptions, setSalesmanOptions] = useState();
  const [channelOptions, setChannelOptions] = useState();
  const [transactionOptions, setTransactionOptions] = useState();

  useEffect(() => {
    if (props.retailerOptions) {
      const retailers = props.retailerOptions.map((retailer) => ({
        label: retailer.code,
        name: retailer.name,
        value: retailer.id,
      }));
      const uniqueRetailers = Array.from(new Set(retailers.map(JSON.stringify))).map(JSON.parse);
      setRetailerOptions(uniqueRetailers);
    }
  }, [props.retailerOptions]);

  useEffect(() => {
    if (props.salesmanOptions) {
      const salesmen = props.salesmanOptions.map((salesman) => ({
        label: salesman.code,
        name: salesman.name,
        value: salesman.id,
      }));
      const uniqueSalesmen = Array.from(new Set(salesmen.map(JSON.stringify))).map(JSON.parse);
      setSalesmanOptions(uniqueSalesmen);
    }
  }, [props.salesmanOptions]);

  useEffect(() => {
    if (props.channelOptions) {
      const channels = props.channelOptions.map((channel) => ({
        label: channel.channel,
        value: channel.channel,
      }));
      const uniqueChannels = Array.from(new Set(channels.map(JSON.stringify))).map(JSON.parse);
      setChannelOptions(uniqueChannels);
    }
  }, [props.channelOptions]);

  useEffect(() => {
    const transactions = [
      { label: 'Pending', value: transactionFilters.PENDING },
      { label: 'Partially Paid', value: transactionFilters.PARTIAL },
      { label: 'Paid', value: transactionFilters.PAID },
      { label: 'SMS Sent', value: transactionFilters.SMS_SENT },
      { label: 'SMS Not Sent', value: transactionFilters.SMS_NOT_SENT },
    ];
    setTransactionOptions(transactions);
  }, []);

  useEffect(() => {
    dispatch([FILTERS, selectedFilters]);
  }, [FILTERS, dispatch]);

  function onRetailerSelected(retailer) {
    const w = [];
    if (retailer) {
      for (const item of retailer) {
        w.push(item.value);
      }
    } else {
    }
    selectedFilters.retailerIds = w;
    dispatch([props.actionType.FILTERS, selectedFilters]);
  }

  function onSalesmanSelected(salesmen) {
    const x = [];
    if (salesmen) {
      for (const item of salesmen) {
        x.push(item.value);
      }
    } else {
    }
    selectedFilters.salesmanIds = x;
    dispatch([props.actionType.FILTERS, selectedFilters]);
  }

  function onChannelSelected(channels) {
    const y = [];
    if (channels) {
      for (const item of channels) {
        y.push(item.value);
      }
    } else {
    }
    selectedFilters.channels = y;
    dispatch([props.actionType.FILTERS, selectedFilters]);
  }

  function onTransactionSelected(transactions) {
    const z = [];
    if (transactions) {
      for (const item of transactions) {
        z.push(item.value);
      }
    } else {
    }
    selectedFilters.transaction = z;
    dispatch([props.actionType.FILTERS, selectedFilters]);
  }

  return (
    <Container className={classes.filterContainer}>
      <Box className={classes.filterHolder}>
        <h3>Retailer</h3>
        <Select
          isMulti
          components={{ MenuList }}
          options={retailerOptions}
          onChange={(value) => onRetailerSelected(value)}
        />
      </Box>
      <Box className={classes.filterHolder}>
        <h3>Salesman</h3>
        <Select isMulti options={salesmanOptions} onChange={(value) => onSalesmanSelected(value)} />
      </Box>
      <Box className={classes.filterHolder}>
        <h3>Channel</h3>
        <Select isMulti options={channelOptions} onChange={(value) => onChannelSelected(value)} />
      </Box>
      <Box className={classes.filterHolder}>
        {/* <h3>Transaction</h3> */}
        <h3>Status</h3>
        <Select
          isMulti
          options={transactionOptions}
          onChange={(value) => onTransactionSelected(value)}
        />
      </Box>
    </Container>
  );
}
