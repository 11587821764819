import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function GridDialog(props) {
  const {
    columns,
    dialogData: { data, title },
  } = { ...props };
  const [open, setOpen] = useState(false);

  const getTabularData = (columns, rowData) => {
    return (
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell
                key={++index}
                align={col.numeric || false ? 'right' : 'left'}
                width={col.width}
              >
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((row, index) => (
            <TableRow key={`row_${++index}`}>
              {columns.map(
                (col, index) =>
                  (row[col.dataKey] !== undefined || !col.merge) && (
                    <TableCell
                      key={`row_cell_${++index}`}
                      align={col.numeric || false ? 'right' : 'left'}
                      width={col.width}
                      rowSpan={col.merge ? row['rowSpan'] : 1}
                    >
                      {col.numeric ? col.formatter(row[col.dataKey]) : row[col.dataKey]}
                    </TableCell>
                  )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    // effect
    if (Object.keys(data)) {
      openDialog();
    }
    return () => {
      closeDialog();
    };
  }, [data]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={closeDialog}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
      <DialogContent>{getTabularData(columns, data)}</DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
