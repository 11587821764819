import React from 'react';
import Layout from 'components/Layout';
import { useGState } from '../../state/store';
import Collection from '../../collection/Collection';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  text: {
    textAlign: 'center',
  },
}));

export default function CollectionIndex(props) {
  const classes = useStyles();
  const { date, branch } = useGState((s) => ({
    date: s.date,
    branch: s.branch,
  }));

  if (!branch || branch.id === -1) {
    return (
      <Layout {...props}>
        <Box className={classes.mainContainer}>
          <Typography className={classes.text}>Please Select a branch</Typography>
        </Box>
      </Layout>
    );
  } else {
    return (
      <Layout {...props}>
        <Collection date={date} branch={branch} />
      </Layout>
    );
  }
}
