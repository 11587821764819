import produce from 'immer';
import { transactionFilters } from 'utils/constants';
import { arraysIntersection } from 'utils/utils';

export const actionType = {
  INIT: 'INIT',
  SELECT_RETAILER: 'SELECT_RETAILER',
  SELECT_ALL_RETAILERS: 'SELECT_ALL_RETAILERS',
  FILTERS: 'FILTERS',
  SYNC_COLLECTION_STATE: 'SYNC_COLLECTION_STATE',
};

const collectionReducer = (state, [action, data]) => {
  return produce(state, (draft) => {
    switch (action) {
      case actionType.INIT: {
        // const updatedList = data.retailers.map((item) => ({
        //   ...item,
        //   //isSelected: false,
        //   paymentStatus: getPaymentStatus(item),
        // }));
        return {
          ...data,
          retailers: createRetailerData(data),
          filteredResult: createRetailerData(data),
        };
      }

      case actionType.SELECT_RETAILER: {
        // for (let itemIndex in state.filteredResult) {
        //   if (state.filteredResult[itemIndex].id === data.id) {
        //     draft.filteredResult[itemIndex].isSelected = data.isChecked;
        //   }
        // }
        break;
      }

      case actionType.SELECT_ALL_RETAILERS: {
        // for (let itemIndex in state.filteredResult) {
        //   draft.filteredResult[itemIndex].isSelected = data.isChecked;
        // }
        break;
      }

      case actionType.SYNC_COLLECTION_STATE: {
        //draft.retailers = data.retailers;
        draft.retailers = createRetailerData(data);
        break;
      }

      case actionType.FILTERS: {
        let filteredList = [];
        if (
          !data.retailerIds.length &&
          !data.salesmanIds.length &&
          !data.channels.length &&
          !data.transaction.length
        ) {
          filteredList = state.retailers;
        } else {
          const filteredArrays = [];
          if (data.retailerIds.length) filteredArrays.push(filterByRetailer(state, data));
          if (data.salesmanIds.length) filteredArrays.push(filterBySalesman(state, data));
          if (data.channels.length) filteredArrays.push(filterByChannel(state, data));
          if (data.transaction.length) filteredArrays.push(filterByTransaction(state, data));

          filteredList = arraysIntersection(filteredArrays);
          //console.log({ filteredList });
          // const allFilteredRetailers = [
          //   ...new Set([
          //     ...filterByRetailer(state, data),
          //     ...filterBySalesman(state, data),
          //     ...filterByChannel(state, data),
          //     ...filterByTransaction(state, data),
          //   ]),
          // ];
          // filteredList = allFilteredRetailers.filter(
          // )
        }
        draft.filteredResult = filteredList;
        break;
      }

      default:
        return state;
    }
  });
};

function createRetailerData(data) {
  const updatedList = data.retailers.map((item) => ({
    ...item,
    //isSelected: false,
    paymentStatus: getPaymentStatus(item),
  }));
  return updatedList;
}

function getPaymentStatus(retailer) {
  if (retailer.smsSent) {
    if (retailer.amountCollected === 0) {
      return transactionFilters.PENDING;
    } else if (retailer.amountCollected >= calculateSum(retailer.ars)) {
      return transactionFilters.PAID;
    } else if (retailer.amountCollected < calculateSum(retailer.ars)) {
      return transactionFilters.PARTIAL;
    }
  } else {
    //return transactionFilters.PENDING;
    return '-';
  }
}

function calculateSum(data) {
  let total = 0;
  for (const item of data) {
    total = total + item.outstanding;
  }
  return total;
}

function filterByRetailer(state, data) {
  return state.retailers.filter((retailer) => data.retailerIds.includes(retailer.id));
}

function filterBySalesman(state, data) {
  let sFilterResult = [];
  const s = [];
  for (const id of data.salesmanIds) {
    for (const item of state.retailers) {
      if (item.ars[0].salesmanId === id) {
        s.push(item);
      }
    }
  }
  sFilterResult = s;
  return sFilterResult;
}

function filterByChannel(state, data) {
  const cFilterResult = state.retailers.filter((retailer) =>
    data.channels.includes(retailer.channel)
  );
  return cFilterResult;
}

function filterByTransaction(state, data) {
  let tFilterResult = [];
  const t = [];
  for (const transac of data.transaction) {
    for (const item of state.retailers) {
      if (transac === transactionFilters.SMS_SENT) {
        if (item.smsSent) {
          t.push(item);
        }
      }
      if (transac === transactionFilters.SMS_NOT_SENT) {
        if (!item.smsSent) {
          t.push(item);
        }
      }
      if (item.paymentStatus === transac) {
        t.push(item);
      }
    }
  }
  tFilterResult = t;
  return tFilterResult;
}

export default collectionReducer;
