import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Assessment';
import DownLoadArIcon from '@material-ui/icons/Receipt';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/styles';
import { Download as DownloadIcon } from 'components/Icons';
import Loading from 'components/Loading';
import Uploader, { DefaultUploaderRender } from 'components/Uploader';
import { saveAs } from 'file-saver';
import React, { useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useAPI, useSnackbar } from 'utils/customHooks';
import fetchApi from 'utils/fetch';
import collectionReducer, { actionType } from './collectionReducer';
import Filters from './Filter';
import Retailers from './Retailers';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: theme.spacing(2),
  },
  button_container: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
  btns: {
    marginRight: theme.spacing(2),
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getRetailerIds(retailers) {
  let ids = [];
  ids = retailers.map((retailer) => {
    return retailer.id;
  });
  return ids;
}

export default function Collection(props) {
  const classes = useStyles();
  const [notif] = useSnackbar();
  const [fetch] = useAPI();
  const [state, dispatch] = useReducer(collectionReducer, {});
  const [open, setOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const actions = [
    { icon: <DownloadIcon />, name: 'Sample', id: 'downloadSample' },
    { icon: <ReportIcon />, name: 'Report', id: 'downloadReport' },
    { icon: <DownLoadArIcon />, name: 'Uploaded AR', id: 'downloadAR' },
    {
      icon: (
        <Uploader
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          url={`/delivery/invoice/ar?branchId=${props.branch?.id}&date=${props.date}`}
          onSuccess={() => reFetchRetailers()}
          onError={() => notif('Oh Snap! Upload Failed', { variant: 'error' })}
          render={(progress, apiInProgress) => (
            <DefaultUploaderRender
              tooltip="Upload AR"
              progress={progress}
              apiInProgress={apiInProgress}
            />
          )}
        />
      ),
      name: 'Upload',
      id: 'upload',
    },
  ];

  // API Call to get the collection data
  const {
    data: collectionData,
    status: collectionFetchStatus,
    refetch: reFetchRetailers,
  } = useQuery(
    props.branch && ['batch_collection', { date: props.date, branchId: props.branch?.id }],
    (_, { date, branchId }) => fetchApi(`/remote-collection?date=${date}&branchId=${branchId}`),
    {
      onSuccess: (collectionData) => {
        if (!collectionData) {
        } else {
          dispatch([actionType.INIT, collectionData]);
        }
      },
      onError: () => {},
      suspense: false,
      useErrorBoundary: false,
    }
  );

  // API Call on collection data to sync the transaction status
  const { data: syncCollectionData, refetch: syncCollection } = useQuery(
    collectionData && ['sync_collection', { date: props.date, branchId: props.branch?.id }],
    (_, { date, branchId }) => fetchApi(`/remote-collection?date=${date}&branchId=${branchId}`),
    {
      onSuccess: () => {
        dispatch([actionType.SYNC_COLLECTION_STATE, syncCollectionData]);
      },
      onError: () => {},
      suspense: false,
      useErrorBoundary: false,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
    }
  );

  // API Call to POST/ send bulk sms on the filtered retailers
  const { refetch: sendBulkSms } = useQuery(
    props.branch && ['bulk_sms', { date: props.date, branchId: props.branch?.id }],
    (_, { date, branchId }) =>
      fetchApi(`/remote-collection/sms-bulk?date=${date}&branchId=${branchId}`, {
        method: 'post',
        data: getRetailerIds(state.filteredResult),
      }),
    {
      onSuccess: () => {
        syncCollection();
      },
      onError: () => {},
      suspense: false,
      useErrorBoundary: false,
      manual: true,
    }
  );

  // API Call to update the collection state--> called on approve/un-approve button
  const { refetch: updateCollectionState } = useQuery(
    props.branch && ['collection_state', { date: props.date, branchId: props.branch?.id }],
    (_, { date, branchId }) =>
      fetchApi(
        `/remote-collection/state?date=${date}&branchId=${branchId}&action=${
          state.isApproved ? 'UNAPPROVE' : 'APPROVE'
        }`,
        {
          method: 'post',
        }
      ),
    {
      onSuccess: () => {
        reFetchRetailers();
      },
      onError: () => {},
      suspense: false,
      useErrorBoundary: false,
      manual: true,
    }
  );

  function download(url, fileName) {
    fetch(
      {
        url: url,
        method: 'get',
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json, blob',
        },
      },
      (resp) => {
        saveAs(resp, `${fileName}.xlsx`);
      },
      () => {}
    );
  }

  function onClickOfFab(id) {
    if (id === 'downloadSample') {
      download(`/delivery/invoice/ar/template?branchId=${props.branch.id}`, 'Sample');
    } else if (id === 'downloadReport') {
      download(
        `/collection/report?branchId=${props.branch.id}&date=${props.date}&isSales=true`,
        `Collection Report(${props.date}_${props.branch.id})`
      );
    } else if (id === 'downloadAR') {
      download(
        `/delivery/invoice/ar/arUpload?branchId=${props.branch.id}&date=${props.date}`,
        `Uploaded AR_${props.date}_${props.branch.id}`
      );
    }
    setOpen(false);
  }

  if (collectionFetchStatus === 'loading') {
    return <Loading text="Customers" />;
  }

  return (
    <Box className={classes.mainContainer}>
      {collectionData ? (
        <Box>
          <Filters
            dispatch={dispatch}
            actionType={actionType}
            retailerOptions={state.retailers}
            salesmanOptions={state.salesmen}
            channelOptions={state.retailers}
          />

          <Retailers
            dispatch={dispatch}
            actionType={actionType}
            retailers={state.filteredResult ? state.filteredResult : []}
          />

          {collectionData.isApproved ? (
            <Button
              className={classes.btns}
              variant="contained"
              color="primary"
              onClick={() => updateCollectionState()}
            >
              Revert approval
            </Button>
          ) : (
            <Container className={classes.button_container}>
              <Button
                className={classes.btns}
                variant="contained"
                color="primary"
                onClick={() => setShowDialog(true)}
              >
                Send SMS
              </Button>

              <Button
                className={classes.btns}
                variant="contained"
                color="primary"
                onClick={() => updateCollectionState()}
              >
                Approve collection
              </Button>
            </Container>
          )}
        </Box>
      ) : (
        <h2>Please upload your AR</h2>
      )}

      <SpeedDial
        ariaLabel="Utility speed dial"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        direction="up"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => onClickOfFab(action.id)}
          />
        ))}
      </SpeedDial>

      {/* Dialog Section */}
      <Dialog
        open={showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Bulk SMS Initiated'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to send SMS to {state.filteredResult ? state.filteredResult.length : 0}{' '}
            retailers. Please confirm your action
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setShowDialog(false);
              sendBulkSms();
            }}
            color="primary"
          >
            Send SMS
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
